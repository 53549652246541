









































































































import {Component, Ref, Vue} from "vue-property-decorator";
import api from "@/api";
import {
  AttachmentDto,
  AttachmentHostType,
  BbsGroupCreateOrUpdateDto,
  DataDictionaryDto,
  DataDictionaryDtoPagedResultDto,
} from "@/api/appService";
import {ElForm} from "element-ui/types/form";
import {CodeToText, regionData} from "element-china-area-data";
import AbSelect from "@/components/AbSelect/index.vue";
import SimpleUploadImage from "@/components/SimpleUploadImage/index.vue";

Vue.use(regionData, CodeToText);

@Component({
  components: {
    AbSelect,
    SimpleUploadImage
  },
})
export default class CreateBbsGroup extends Vue {
  @Ref() readonly dataForm!: ElForm;
  dataId = 0;
  form: BbsGroupCreateOrUpdateDto = {};
  volunteerLevelList: DataDictionaryDto[] = [];
  BbsGroupTypeList: DataDictionaryDto[] = [];
  roleRule = {
    name: [
      {
        required: true,
        message: "群组名称必填",
        trigger: "blur",
      },
    ],
    groupTypeId: [
      {
        required: true,
        message: "群组类型必填",
        trigger: "blur",
      },
    ],
  };

  // 保存
  async save() {
    (this.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        if (this.$route.params.id) {
          await api.bbsGroup
            .update({
              body: this.form,
            })
            .then(() => {
              this.$router.back();
              this.$message.success("更新成功");
            });
        } else {
          await api.bbsGroup
            .create({
              body: this.form,
            })
            .then(() => {
              this.$router.back();
              this.$message.success("新建成功");
            });
        }
      } else {
        this.$message.error("请检查表单");
      }
    });
  }

  async created() {
    await this.fetchBbsGroupTypeList();

    if (this.$route.params.id) {
      this.dataId = Number(this.$route.params.id);
      await api.bbsGroup.get({id: this.dataId}).then((res) => {
        this.form = {...res};
      });
    }

    await api.dataDictionary
      .getDataDictionaryListByKey({
        maxResultCount: 65535,
        key: "VolunteerLevel",
      })
      .then((res: DataDictionaryDtoPagedResultDto) => {
        this.volunteerLevelList = res.items!;
      });

    this.$nextTick(() => {
      this.dataForm.clearValidate();
    });
  }

  // 折叠面板Collapse 事件
  handleCollapseChange(val: any) {
    console.log(val, "Collapse");
  }

  async fetchBbsGroupTypeList() {
    await api.dataDictionary
      .getDataDictionaryListByKey({
        key: "BbsGroupType",
        maxResultCount: 1000,
      })
      .then((res: DataDictionaryDtoPagedResultDto) => {
        this.BbsGroupTypeList = res!.items!;
      });
  }

  get hostType() {
    return AttachmentHostType.BbsGroupTitleImage;
  }

  //返回
  cancel() {
    this.$router.back();
  }
}
